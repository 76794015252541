import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'input',
    'status',
    'smsButton',
    'callButton',
    'countryCode',
    'inputFields',
  ];

  connect() {
    const hasPhoneNumber = this.inputTarget.value.trim() !== '';
    const hasCountryCode =
      this.hasCountryCodeTarget && this.countryCodeTarget.value.trim() !== '';

    this.toggleButtons(hasPhoneNumber || hasCountryCode);
  }

  handleInput() {
    const hasPhoneNumber = this.inputTarget.value.trim() !== '';
    const hasCountryCode =
      this.hasCountryCodeTarget && this.countryCodeTarget.value.trim() !== '';

    this.toggleButtons(hasPhoneNumber || hasCountryCode);
  }

  toggleButtons(shouldShow) {
    const action = shouldShow ? 'remove' : 'add';
    this.smsButtonTarget.classList[action]('d-none');
    this.callButtonTarget.classList[action]('d-none');
  }

  validateNumber() {
    const phoneNumber = this.inputTarget.value.replace(/\s+/g, '');
    // This regex validates phone numbers in both international (+ followed by 2-15 digits)
    // and local formats (10-15 digits without a +).
    const phoneRegex = /^(?:\+?[0-9]\d{1,14}|\d{10,15})$/;
    const isValid = phoneRegex.test(phoneNumber);

    this.updateStatus(isValid);
    if (isValid) {
      this.updateButtons(phoneNumber);
    }
    return isValid;
  }

  validateSms(event) {
    event.preventDefault();
    this.clearStatusMessage();

    if (this.smsButtonTarget.dataset.url === '#' || !this.validateNumber()) {
      return;
    }

    this.postRequest(this.smsButtonTarget.dataset.url, 'sms');
  }

  validateCall(event) {
    event.preventDefault();
    this.clearStatusMessage();

    if (this.callButtonTarget.dataset.url === '#' || !this.validateNumber()) {
      return;
    }

    this.postRequest(this.callButtonTarget.dataset.url, 'call');
  }

  postRequest(url, medium) {
    fetch(url, {
      method: 'POST',
      headers: this.headers,
    })
      .then((response) => {
        if (response.ok) {
          this.handleResponse(medium);
        } else {
          this.handleError(
            `Failed to verify via ${medium}, please double check your phone number.`
          );
        }
        return response;
      })
      .catch((error) => {
        this.handleError(`Error during ${medium} verification: ${error}`);
        throw error;
      });
  }

  handleResponse(medium) {
    if (medium === 'sms') {
      medium = 'SMS';
    } else {
      medium = medium.charAt(0).toUpperCase() + medium.slice(1);
    }
    this.statusTarget.innerHTML = `Test ${medium} created.`;
  }

  handleError(errorMessage) {
    console.error(errorMessage);
    this.statusTarget.innerHTML = errorMessage;
  }

  clearStatusMessage() {
    this.statusTarget.innerHTML = '';
  }

  updateStatus(isValid) {
    if (isValid) {
      this.inputTarget.classList.remove('is-invalid');
      this.inputTarget.classList.add('amba-phone-check-is-valid');
    } else {
      this.inputTarget.classList.remove('amba-phone-check-is-valid');
      this.inputTarget.classList.add('is-invalid');
    }
  }

  updateButtons(phoneNumber = this.inputTarget.value.replace(/\s+/g, '')) {
    const countryCode = this.hasCountryCodeTarget
      ? this.countryCodeTarget.value
      : '';
    const smsUrl = `/phone_number_tests?phone_prefix_country_code=${countryCode}&phone_number=${phoneNumber}&medium=sms`;
    const callUrl = `/phone_number_tests?phone_prefix_country_code=${countryCode}&phone_number=${phoneNumber}&medium=phone`;

    if (this.hasSmsButtonTarget) {
      this.smsButtonTarget.dataset.url = phoneNumber ? smsUrl : '#';
    }
    if (this.hasCallButtonTarget) {
      this.callButtonTarget.dataset.url = phoneNumber ? callUrl : '#';
    }
  }

  get headers() {
    return {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
        ?.content,
      'Content-type': 'application/json; charset=UTF-8',
    };
  }
}
