import { Controller } from 'stimulus';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import { makeTransparent, normalize } from './chart_controller/hex_colors';
import { DEFAULT_POINT_SHAPE } from './chart_controller/shapes_and_dashes';
import {
  withDisconnectBeforeCache,
  withPreviewPrevention,
} from '../javascript/lib/stimulus_turbo';
import { KeyboardNavigationPlugin } from './chart_controller/plugins/keyboard_navigation_plugin';

Chart.register(annotationPlugin);

const BRAND_ORANGE = '#ec8333';
const TICK_BORDER_COLOR = '#A1A6AA';

const CLASS_HIDDEN = 'd-none';

const SECONDS_IN_WEEK = 60 * 60 * 24 * 7;
const SECONDS_IN_MONTH = 60 * 60 * 24 * 30;

export default turboAware(
  class extends Controller {
    static values = {
      data: Object,
      options: Object,
      properties: Array,
    };

    static targets = ['canvas', 'noDataText'];

    connect() {
      if (Object.keys(this.dataValue).length === 0) {
        this.noDataTextTarget.classList.remove(CLASS_HIDDEN);
        this.canvasTarget.classList.add(CLASS_HIDDEN);
      } else {
        // Render the chart on the canvas one frame after adding the templates
        // so that the targets are picked up properly
        requestAnimationFrame(() => {
          this.chart = new Chart(this.canvasTarget, {
            type: 'line',
            data: this.chartData,
            options: this.chartOptions,
            plugins: [new KeyboardNavigationPlugin()],
          });
        });
      }
    }

    disconnect() {
      if (this.chart) this.chart.destroy();
    }

    get chartData() {
      return {
        datasets: [
          {
            id: this.dataValue.id,
            label: this.dataValue.name,
            hidden: this.dataValue.hidden,
            data: this.formatValues(this.dataValue.values),
            alerted: this.dataValue.alerted,
            borderColor: normalize(this.dataValue.color) || '#000000',
            borderWidth: 2,
            hoverBorderWidth: 2,
            pointBackgroundColor: this.dataValue.color,
            pointBorderWidth: 1,
            pointBorderColor: '#ffffff',
            pointHoverBorderWidth: 1,
            // Prevent the cubic interpolation to get the line going
            // above or below local minimum/maximum values to avoid
            // giving a false sense that the values went lower/higher
            cubicInterpolationMode: 'monotone',
            ...DEFAULT_POINT_SHAPE,
            tooltip: {
              callbacks: {
                label: function (context) {
                  var label = context.dataset.label || '';

                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += context.raw.formatted;
                  }
                  return label;
                },
              },
            },
          },
        ],
      };
    }

    get chartOptions() {
      return {
        plugins: {
          legend: {
            // Legend is rendered in HTML for better accessibility
            display: false,
            labels: {
              usePointStyle: true,
            },
          },
          annotation: {
            annotations: {
              lower: {
                display: this.optionsValue.annotation_lower_y_max,
                type: 'box',
                yMax: this.annotation_lower_y_max,
                borderWidth: 0,
                backgroundColor: makeTransparent(BRAND_ORANGE, '40'),
              },
              higher: {
                display: this.optionsValue.annotation_higher_y_min,
                type: 'box',
                yMin: this.annotation_higher_y_min,
                borderWidth: 0,
                backgroundColor: makeTransparent(BRAND_ORANGE, '40'),
              },
            },
          },
        },
        tension: 0.3,
        hover: {
          mode: 'nearest',
        },
        elements: {
          point: {
            // Slightly enlarged hit radius to make tooltip appear more easily
            hitRadius: 10,
          },
        },
        animation: {
          duration: 300,
        },
        scales: {
          x: {
            type: 'time',
            min: this.optionsValue.xmin,
            max: this.optionsValue.xmax,
            time: {
              unit: this.xAxisUnit,
              tooltipFormat: 'E dd LLLL h:m aaa',
              displayFormats: {
                day: 'd MMM', // 21 Jan, 5 Feb...
                week: 'd MMM', // 21 Jan, 5 Feb...
                month: 'MMM ', // Jan, Feb, Mar...
              }, // https://date-fns.org/v2.22.1/docs/format
            },
            ticks: {
              padding: 10,
            },
            grid: {
              display: true,
              drawTicks: false,
              color: TICK_BORDER_COLOR,
              borderDash: [5, 5],
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: this.optionsValue.ytitle,
            },
            afterFit: (axis) => {
              const values = this.dataValue.values.map((value) => value.y);
              const allValuesPositive = values.every((value) => value >= 0);

              if (allValuesPositive && axis.min < 0) {
                axis.start = 0;
                axis.min = 0;
              }
            },
            ...this.yAxisOptions,
          },
        },
      };
    }

    get annotation_lower_y_max() {
      if (!this.optionsValue.annotation_lower_y_max) return false;

      if (this.optionsValue.y_axis_format === 'duration') {
        return this.optionsValue.annotation_lower_y_max / 60 / 60;
      } else {
        return this.optionsValue.annotation_lower_y_max;
      }
    }

    get annotation_higher_y_min() {
      if (!this.optionsValue.annotation_higher_y_min) return false;

      if (this.optionsValue.y_axis_format === 'duration') {
        return this.optionsValue.annotation_higher_y_min / 60 / 60;
      } else {
        return this.optionsValue.annotation_higher_y_min;
      }
    }

    get yAxisOptions() {
      let options = {};

      if (this.optionsValue.y_axis_format === 'percentage') {
        options = {
          min: 0,
          max: 100,
        };
      } else if (this.optionsValue.y_axis_format === 'duration') {
        options = {
          ticks: {
            stepSize: 1,
          },
          title: {
            display: true,
            text: `${this.optionsValue.ytitle} (Hours)`,
          },
          grace: '70%',
        };
      } else {
        // If no values have decimal points, only use full integers for the
        // y-axis scale
        const values = this.dataValue.values.map((data) => data.y);

        if (values.every((val) => val % 1 === 0)) {
          options.ticks = {
            precision: 0,
          };
        }

        // Pad the y-axis scale either side to give the user a little more context
        options.grace = '10%';
      }

      return options;
    }

    get xAxisUnit() {
      const rangeDurationInSeconds =
        (new Date(this.optionsValue.xmax) - new Date(this.optionsValue.xmin)) /
        1000;

      if (rangeDurationInSeconds > 4 * SECONDS_IN_MONTH) {
        return 'month';
      } else if (rangeDurationInSeconds > 3 * SECONDS_IN_WEEK) {
        return 'week';
      } else {
        return 'day';
      }
    }

    formatValues(values) {
      if (this.optionsValue.y_axis_format === 'duration') {
        return values.map((value) => {
          value.y = value.y / 60 / 60;

          return value;
        });
      } else {
        return values;
      }
    }
  }
);

function turboAware(controller) {
  controller.prototype.connect = withPreviewPrevention(
    withDisconnectBeforeCache(controller.prototype.connect)
  );
  // As we're not connecting, there's no need to disconnect
  // either when running a preview. This avoids putting checks
  // in the controllers for missing variables that only happen
  // because the controller was not connected on a preview page
  controller.prototype.disconnect = withPreviewPrevention(
    controller.prototype.disconnect
  );

  return controller;
}
